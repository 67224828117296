.App {
  text-align: center;
}

.control-buttons {
  position: fixed;
  z-index: 1000;
}

.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
